/**
 * Vuetify Styles Overrides
 */
.v-application .d-flex {
  min-width: 0;
}

.v-application p {
  margin-bottom: 20px;
}

.v-list--dense .v-list-item .v-list-item__title {
  line-height: 1.2rem !important;
}

.v-list-item__title {
  font-size: 0.975rem;
  font-weight: 600;
}

.v-list-item__action:first-child,
.v-list-item__icon:first-child {
  margin-right: 14px !important;
}

.v-application--is-rtl .v-list-item__action:first-child,
.v-application--is-rtl .v-list-item__icon:first-child {
  margin-right: 0 !important;
  margin-left: 14px !important;
}

.v-list-item__action:first-child,
.v-list-item__icon:first-child {
  margin-right: 14px !important;
}

.v-list-group__header__append-icon .v-icon {
  font-size: 1rem;
}

.v-list-group__header .v-list-item__icon.v-list-group__header__append-icon {
  min-width: 0 !important;
}

.v-list-item__icon {
  margin: auto;
  justify-content: center;
}

.v-list-group--sub-group .v-list-group__header {
  padding-left: 8px !important;
}

.v-list-group__items {
  margin-bottom: 8px;
}

.v-tabs-items {
  background-color: transparent !important;
}

.theme--dark.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
  background-color: #273743;
}

.v-window__prev,
.v-window__next {
  background: none !important;
}

.v-messages__message {
  line-height: 14px;
}

.truncate-ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
  width: 80%;
}

.hidden {
  visibility: hidden;
}

html, body {
  overflow-x: hidden;
}

.theme--light .title-text {
  color: rgba(0, 0, 0, 0.87);
}

.cursor--blocked {
  cursor: not-allowed;
}

.btn-close {
  position: absolute;
  right: 10px;
  top: 10px;
}

.inside-speed-dial.v-speed-dial--is-active:not(#extend-expiry) .v-speed-dial__list {
  margin-top: -17px;
}
.inside-speed-dial.v-speed-dial--is-active .v-speed-dial__list {
  background-color: white;
  height: 55px;
}
.inside-speed-dial.v-speed-dial--is-active .v-speed-dial__list.v-btn.v-btn--disabled {
  background-color: #dde5ed !important;
}
.inside-speed-dial #extend-expiry .v-speed-dial__list {
  margin-top: 0 !important;
}

.theme--light .custom-form .v-text-field--outlined > .v-input__control > .v-input__slot {
  background-color: white !important;
}

.theme--light .custom-form .v-btn--outlined {
  background-color: white;
}

.no-pointer-events {
  pointer-events: none;
}

.theme--dark.promo-code-box fieldset {
  border-color: #ffffff;
}

.theme--light.promo-code-box:not(.v-input--is-disabled) fieldset {
  border-color: #000000de;
}

.theme--light.promo-code-box.v-input--is-disabled fieldset {
  border-color: rgba(190, 190, 190, 0.87) !important;
}

.position-relative {
  position: relative !important;
}

.bring-to-front {
  z-index: 100;
}

.float-middle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.shake {
  -webkit-animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
          animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
}

@-webkit-keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }
  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }
  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}
.block-blurred {
  filter: blur(4px) brightness(0.95);
  pointer-events: none;
}

.check-background {
  background-color: rgba(76, 169, 82, 0.15);
}

.transform-chevron {
  transform: scale(1.3);
}

.transform-btn {
  transform: scale(1.05);
}

.smooth-transition {
  transition: all 0.2s ease !important;
}

.v-application .body-1.line-height-pivot {
  line-height: 28px;
  margin-top: 1px;
}

.mt-n01 {
  margin-top: -2px;
}

.mx-01 {
  margin-left: 1px;
  margin-right: 1px;
}

.max-width-20 {
  max-width: 20vw;
}

.max-width-15 {
  max-width: 15vw;
}

.max-width-10 {
  max-width: 10vw;
}

.overflow-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.v-application .promo-accept-btn {
  transform: scale(1.1);
  margin-top: -7px !important;
  margin-right: 6px !important;
}

/* Override default roboto font with feature font */
.feature-font,
.v-application [class*=text-].feature-font,
.v-application [class*=subtitle-].feature-font {
  font-family: "Questrial", sans-serif !important;
}

/* Override default roboto font with feature font */
.inter-font,
.v-application [class*=text-].inter-font,
.v-application [class*=subtitle-].inter-font {
  font-family: "Inter", sans-serif !important;
  letter-spacing: 0.001em !important;
}

.v-application .text-h6 {
  line-height: 1.5rem;
  letter-spacing: 0.001em !important;
}

.goog-te-gadget {
  font-family: Roboto, "Open Sans", sans-serif !important;
  text-transform: uppercase;
}

.goog-te-gadget-simple {
  background-color: white !important;
  border-collapse: collapse;
  border-color: silver;
  border-style: solid;
  border-width: 1px;
  padding: 4px !important;
  border-radius: 6px !important;
  display: inline-block;
  cursor: pointer;
  box-shadow: 0 3px 10px -2px rgba(85, 85, 85, 0.08), 0px 2px 20px 0px rgba(85, 85, 85, 0.06), 0px 1px 30px 0px rgba(85, 85, 85, 0.03);
}

.goog-te-gadget-simple:hover {
  border-color: currentColor;
}

.goog-te-menu2 {
  max-width: 100%;
}

.goog-te-menu-value {
  color: rgba(0, 0, 0, 0.6) !important;
}

.goog-te-menu-value span:nth-child(5) {
  display: none;
}

.goog-te-menu-value img {
  display: none;
}

.goog-te-menu-value span:nth-child(3) {
  border: none !important;
}

.goog-te-gadget-icon {
  display: none;
}

.goog-te-banner-frame.skiptranslate {
  display: none !important;
}

body {
  top: 0 !important;
}

/* ================================== *\
    Mediaqueries
\* ================================== */
@media (max-width: 600px) {
  #google_translate_element {
    bottom: calc(100% - 50% - 53px);
    left: 16px !important;
    width: 100% !important;
  }
  #google_translate_element goog-te-gadget {
    width: 100% !important;
  }
  #google_translate_element .skiptranslate {
    width: 100% !important;
  }
  #google_translate_element .goog-te-gadget-simple {
    width: calc(100% - 32px) !important;
    text-align: center;
  }
}